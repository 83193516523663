@import url('https://fonts.googleapis.com/css?family=Public+Sans:300,400,500,600,700,800&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Public Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.left-menu .ant-menu-item-selected {
  background-color: #2192ff !important;
  color: white;
}

.round-header-btn {
  background-color: transparent;
  border: none;
}

.round-header-btn:hover {
  border: none;
  color: black;
  background-color: #bfbfbf;
}

.homepage-iframe {
  margin-top: -75px;
  height: 95.5vh;
  overflow: hidden;
}
